import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { budgetApiActions } from './actions';

export interface State {
  budgets: any[];
  loading: boolean;
  error: string;
}

export const initialState: State = {
  budgets: [],
  loading: false,
  error: '',
};

export const budgetFeature = createFeature({
  name: 'budget',
  reducer: createReducer(
    initialState,
    on(budgetApiActions.loadBudget, (state) => ({
      ...state,
      loading: true,
    })),
    on(budgetApiActions.loadBudgetSuccess, (state, { budgets }) => ({
      ...state,
      budgets,
      loading: false,
    })),
    on(budgetApiActions.loadBudgetFailure, (state) => ({
      ...state,
      loading: false,
      error: 'Failed to load program configs',
    })),
    on(budgetApiActions.saveBudget, (state) => ({
      ...state,
      loading: true,
    })),
    on(budgetApiActions.saveBudgetSuccess, (state, { budget }) => ({
      ...state,
      budgets: [...state.budgets, budget],
      loading: false,
    })),
    on(budgetApiActions.deleteBudget, (state) => ({
      ...state,
      loading: true,
    })),
    on(budgetApiActions.deleteBudgetSuccess, (state) => ({
      ...state,
      loading: false,
    }))
  ),
});
export const { selectLoading, selectBudgets } = budgetFeature;
export const sortedBudgets = createSelector(selectBudgets, (budgets: any[]) => {
  return budgets
    .map((budget) => ({
      ...budget,
      startDate: new Date(budget.startDate),
      endDate: new Date(budget.endDate),
    }))
    .filter(
      (budget) =>
        !isNaN(budget.startDate.getTime()) && !isNaN(budget.endDate.getTime())
    )
    .sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
});
