import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Budget } from '@tdf/rtpca-models';

export const budgetApiActions = createActionGroup({
  source: 'Budget API',
  events: {
    'Load Budget': emptyProps(),
    'Load Budget Success': props<{ budgets: Budget[] }>(),
    'Load Budget Failure': props<{ error: string }>(),
    'Save Budget': props<{ budget: Budget; file?: any }>(),
    'Save Budget Success': props<{ budget: Budget }>(),
    'Save Budget Failure': props<{ error: string }>(),
    'Delete Budget': props<{ budgetId: number }>(),
    'Delete Budget Success': props<{ budgetId: number }>(),
    'Delete Budget Failure': props<{ error: string }>(),
  },
});
